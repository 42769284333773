import { loggedIn } from 'Helpers/session';

const REFRESH_LOGGED_IN = 'session/REFRESH_LOGGED_IN';
const SET_SHOW_KYC_BANNER = 'session/SET_SHOW_KYC_BANNER';
const SET_SHOW_NDL_CHANGE = 'session/SET_SHOW_NDL_CHANGE';
const LOAD_CUSTOMER_CONSENT_MESSAGES = 'session/LOAD_CUSTOMER_CONSENT_MESSAGES';
const LOAD_RESTRICTIONS = 'session/LOAD_RESTRICTIONS';
const LOAD_KYC_STRATEGIES_V2 = 'session/LOAD_KYC_STRATEGIES_V2';
const LOAD_DEPOSIT_LIMIT_INFO = 'session/LOAD_DEPOSIT_LIMIT_INFO';
const CLOSE_DEPOSIT_LIMIT_CONFIRMATION = 'session/CLOSE_DEPOSIT_LIMIT_CONFIRMATION';

export const refreshLoggedIn = () => ({
  type: REFRESH_LOGGED_IN,
});

export const setShowKycBanner = (showKycBanner) => ({
  type: SET_SHOW_KYC_BANNER,
  showKycBanner,
});

export const setShowNdlChange = (showNdlChange) => ({
  type: SET_SHOW_NDL_CHANGE,
  showNdlChange,
});

export const loadCustomerConsentMessages = (messages) => ({
  type: LOAD_CUSTOMER_CONSENT_MESSAGES,
  messages,
});

export const loadRestrictions = (restrictions) => ({
  type: LOAD_RESTRICTIONS,
  restrictions,
});

export const loadKycStrategiesV2 = (strategies) => ({
  type: LOAD_KYC_STRATEGIES_V2,
  strategies,
});

export const loadDepositLimitInfo = (limit, api) => ({
  type: LOAD_DEPOSIT_LIMIT_INFO,
  payload: {
    showConfirmation: limit.pending,
    pendingLimit: limit.pending ? {
      amount: limit.amount,
      period: limit.period?.description || 'no_limit',
    } : null,
    api,
  },
});

export const closeDepositLimitConfirmation = () => ({
  type: CLOSE_DEPOSIT_LIMIT_CONFIRMATION,
});

const initialState = {
  loggedIn: loggedIn(),
  showKycBanner: false,
  depositLimit: {
    showConfirmation: false,
    pendingLimit: null,
    api: null,
  },
  showNdlChange: false,
  customerConsentMessages: [],
  restrictions: [],
  kycStrategiesV2: [],
  kycStrategiesV2WereLoaded: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REFRESH_LOGGED_IN:
      return {
        ...state,
        loggedIn: loggedIn(),
      };
    case SET_SHOW_KYC_BANNER:
      return {
        ...state,
        showKycBanner: action.showKycBanner,
      };
    case SET_SHOW_NDL_CHANGE:
      return {
        ...state,
        showNdlChange: action.showNdlChange,
      };
    case LOAD_CUSTOMER_CONSENT_MESSAGES:
      return {
        ...state,
        customerConsentMessages: action.messages,
      };
    case LOAD_RESTRICTIONS:
      return {
        ...state,
        restrictions: action.restrictions,
      };
    case LOAD_KYC_STRATEGIES_V2:
      return {
        ...state,
        kycStrategiesV2: action.strategies,
        kycStrategiesV2WereLoaded: true,
      };
    case LOAD_DEPOSIT_LIMIT_INFO:
      return {
        ...state,
        depositLimit: {
          ...action.payload,
        },
      };
    case CLOSE_DEPOSIT_LIMIT_CONFIRMATION:
      return {
        ...state,
        depositLimit: initialState.depositLimit,
      };
    default:
      return state;
  }
};
